import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";

import Layout from "../components/layout";

const Company = ({ data }) => {
  return (
    <Layout title={`Company`}>
      <Container>
        <Row>
          <Col className="mt-5">
            <h1>{data.markdownRemark.frontmatter.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-5">
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            ></div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Company;

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { title: { eq: "Company" } }) {
      html
      frontmatter {
        description
        title
      }
    }
  }
`;
